import React from 'react';

import './Document.scss';
import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { customPropTypes, sanitizer } from '../../util';

const Document = ({ data }) => {
  const { content, id } = data;
  return (
    <section id={id} className="Document">
      <div className="container">
        <div className="content" dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
      </div>
    </section>
  );
};

Document.propTypes = checkExtraProps({
  data: PropTypes.shape({
    ...customPropTypes.basePropTypes,
    content: PropTypes.string.isRequired,
  }).isRequired,
});

export default Document;

export const fragments = graphql`
  fragment Document on WpPage_Flexiblelayout_FlexibleChildren_Document {
    content
    id
    jumpToLinkTitle
    name
  }

  fragment Document_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_Document {
    content
    id
    jumpToLinkTitle
    name
  }
`;
